@import 'variables';

.collapsible {
  transition: height 0.3s ease-in;
  overflow: hidden;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}

.bold {
  font-weight: bold;
}

.highlighted {
  color: $color-brand-highlight;
}

.badge {
  background-color: $badge-bg-color;
  color: $badge-text-color;
  font-family: $font-family;
  font-weight: 600;
  padding-top: 2px;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  z-index: 1;
}

.badge-inverse {
  background-color: $inverse-badge-bg-color;
  color: $inverse-badge-text-color;
  font-family: $font-family;
  padding-top: 2px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  z-index: 1;
  font-weight: bold;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.sport-icon,
.flag-icon,
.node-icon {
  background-repeat: no-repeat;
  display: block;
  background-size: 100% 100%;
}

.sport-icon {
  @each $name, $value in $sport-icons {
    &.#{$name} {
      background-image: url('/assets/images/sport-icons/#{$value}.svg');

      &.text-dark,
      &.text-light,
      &.brand,
      &.accent {
        background-image: none;
        height: 100%;
        width: 100%;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
      }

      &.text-light {
        background-color: $color-text-primary;
        mask-image: url('/assets/images/sport-icons/#{$value}.svg');
      }

      &.brand {
        background: url('/assets/images/sport-icons/#{$value}.svg');
      }

      &.accent {
        background-color: $color-brand-accent;
        mask-image: url('/assets/images/sport-icons/#{$value}.svg');
      }
    }
  }
}

.flag-icon {
  &.icon-generic {
    background-image: url('/assets/images/flag-icons/Generic.svg');
  }

  @each $name, $value in $flag-icons {
    &.#{$name} {
      background-image: url('/assets/images/flag-icons/#{$value}.svg');
    }
  }
}

.node-icon {
  &.icon-generic {
    background-image: url('/assets/images/node-icons/Generic.svg');
  }

  @each $name, $value in $node-icons {
    &.#{$name} {
      background-image: url('/assets/images/node-icons/#{$value}.svg');
    }
  }
}

.hidden {
  display: none !important;
}

.blink {
  animation: blink 1s steps(1, end) infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cdk-overlay-connected-position-bounding-box {
  left: unset !important;
  width: unset !important;
  -webkit-overflow-scrolling: touch; /*For smoother scrolling on iOS*/
}

.disable-container {
  pointer-events: none;
}

.pinned-to-top {
  position: fixed !important;
  top: 0 !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-disabled {
  height: 100%;
  overflow: hidden;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent !important;
}

.text-gradient-secondary {
  background: linear-gradient(90.93deg, $color-brand-text 37.45%, darken($color-brand-text, 5) 66.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.loader {
  height: 2.4rem;
  width: 2.4rem;
  color: $color-brand-highlight-light;
  position: relative;
  display: inline-block;
  border: 0.2rem solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;

  &.white {
    color: white;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

// .even-row {
//   background: #111415;
// }

.cursor-pointer {
  cursor: pointer;
}

app-odd,
app-odd-with-label,
.event-details {
  cursor: pointer;
}

.responsive-wrapper {
  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: minmax(0, 40rem) minmax(0, 1fr) minmax(0, 40rem);
    // max-width: 1680px;
    // margin: auto;
  }

  @media screen and (min-width: 1440px) {
    display: grid;
    grid-template-columns: minmax(0, 40rem) minmax(0, 1fr) minmax(0, 40rem);
  }

  @media screen and (min-width: 1920px) {
    display: grid;
    grid-template-columns: minmax(0, 48rem) minmax(0, 1fr) minmax(0, 48rem);
  }

  &.responsive-blog {
    @media screen and (min-width: 1280px) {
      grid-template-columns: minmax(0, 30rem) minmax(0, 1fr) minmax(0, 30rem);
      gap: 2.4rem;
    }

    @media screen and (min-width: 1440px) {
      grid-template-columns: minmax(0, 36rem) minmax(0, 1fr) minmax(0, 36rem);
    }

    @media screen and (min-width: 1920px) {
      display: grid;
      grid-template-columns: minmax(0, 48rem) minmax(0, 1fr) minmax(0, 48rem);
      gap: 4rem;
    }
  }

  &.responsive-live {
    @media screen and (min-width: 1280px) {
      grid-template-columns: minmax(0, 40rem) minmax(0, 1fr) minmax(0, 36rem);

      .left-panel {
        padding: 1.6rem 2rem !important;
      }
    }

    @media screen and (min-width: 1560px) {
      grid-template-columns: minmax(0, 48rem) minmax(0, 1fr) minmax(0, 36rem);
    }

    @media screen and (min-width: 1920px) {
      display: grid;
      grid-template-columns: minmax(0, 52rem) minmax(0, 1fr) minmax(0, 48rem);
    }

    .left-panel {
      padding: 1.6rem 0;
      overflow: hidden;
    }
    .right-panel {
      padding: 1.6rem;
      padding-left: 1.6rem;
    }
  }

  &.responsive-prematch {
    @media screen and (min-width: 1280px) {
      grid-template-columns: minmax(0, 36rem) minmax(0, 1fr) minmax(0, 36rem);
      // gap: 1.6rem;
    }

    @media screen and (min-width: 1560px) {
      // grid-template-columns: minmax(0, 40rem) minmax(0, 1fr) minmax(0, 36rem);
    }

    @media screen and (min-width: 1920px) {
      grid-template-columns: minmax(0, 48rem) minmax(0, 1fr) minmax(0, 48rem);
    }

    .left-panel {
      padding: 1.6rem 0;
    }
    .right-panel {
      padding: 1.6rem;
      padding-left: 1.6rem;
    }
  }

  .left-panel,
  .right-panel {
    display: none;
    padding: 2.4rem;
    height: calc(var(--vh, 1vh) * 100 - 8rem);
    overflow: auto;

    @media screen and (min-width: 1280px) {
      display: block;
      height: calc(var(--vh, 1vh) * 100 - 12rem);
    }

    @media screen and (min-width: 1440px) {
      padding: 2.4rem 3.2rem;
    }
  }

  .coupon-bets {
    width: 100%;
    position: relative;
    border: 0.1rem solid $color-brand-dark-2;
    border-radius: 0.4rem;
    margin-top: 3.2rem;
    // max-height: 64rem;
    // overflow: auto;

    // .coupon {
    //   padding: 0.8rem;
    // }
  }

  .coupon-bets-tabs {
    display: flex;
    position: sticky;
    top: -1.8rem;
    background: $color-site-background;
    z-index: 1000;

    > div {
      width: 100%;
      border-bottom: 0.1rem solid $color-brand-dark-2;
      text-align: center;
      color: white;
      font-weight: 600;
      padding: 1.6rem 0;
      font-size: 1.2rem;

      color: rgba(white, 0.6);
      cursor: pointer;

      &.active {
        border-color: $color-brand-highlight-light;
        color: rgba(white, 1);
        pointer-events: none;
      }
    }
  }

  .icons {
    display: flex;
    justify-content: center;
    margin-top: 3.2rem;

    .icons-wrapper {
      display: flex;
      align-items: center;
      gap: 4.8rem;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0.6;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        .icon {
          display: block;
          height: 2.4rem;
        }

        span {
          font-size: 1.1rem;
          text-align: center;
          display: block;
          padding-top: 0.4rem;
        }
      }
    }
  }
}

.coupon-fab-visible {
  @media screen and (max-width: 1280px) {
    // .live-content-wrapper,
    // #prematch-betting-wrapper,
    // #match-wrapper {
    //   padding-bottom: 8.8rem;
    // }
  }
}

.blog-article-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  .text {
    padding-top: 3.2rem;
  }

  p {
    font-size: 1.4rem;
    padding-bottom: 1.2rem;
    line-height: 1.5;

    @media screen and (min-width: 1280px) {
      font-size: 1.5rem;
      line-height: 1.6;
      padding-bottom: 1.4rem;
    }
  }

  h1 {
    font-size: 2.8rem;
    padding-bottom: 1rem;
    line-height: 1.4;
  }

  h2 {
    font-size: 2.2rem;
    padding-top: 2.8rem;
    padding-bottom: 2rem;
    line-height: 1.4;
  }

  h3 {
    font-size: 2rem;
    padding-top: 2rem;
    padding-bottom: 1.4rem;
    line-height: 1.4;
  }

  h4 {
    font-size: 1.8rem;
    padding-bottom: 1.2rem;
    padding-top: 1.2rem;
    line-height: 1.4;
  }

  h5 {
    font-size: 1.5rem;
    padding-top: 1.6rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    line-height: 1.4;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    margin-top: 1.6rem;
  }

  a {
    color: $color-brand-text !important;
  }

  h6 {
    font-size: 1.2rem;
    font-weight: 600;
    padding-bottom: 1.6rem;
    padding-top: 2.4rem;
    text-transform: uppercase;
    line-height: 1.4;
  }
}

@media screen and (min-width: 1280px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #32363c $color-site-background;
  }
  *::-webkit-scrollbar {
    width: 14px;
  }
  *::-webkit-scrollbar-track {
    background: $color-site-background;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #32363c;
    border-radius: 20px;
    border: 3px solid $color-site-background;
  }
}

select {
  outline: none !important;
}

input:-webkit-autofill,
*:-webkit-autofill {
  transition-delay: 9999999s;
  -webkit-transition-delay: 9999999s;
}



.driver-popover.victory-theme {
  background-color: #1A1C1F;
  color: #FFF;
}

.driver-popover.victory-theme .driver-popover-title {
  font-size: 20px;
  color: #FF691D
}

.driver-popover.victory-theme .driver-popover-title,
.driver-popover.victory-theme .driver-popover-description,
.driver-popover.victory-theme .driver-popover-progress-text {
  color: #FFF;
}

.driver-popover.victory-theme .driver-popover-progress-text {
  margin-right: 4px;
}

.driver-popover.victory-theme button {
  flex: 1;
  text-align: center;
  background-color: #FF691D;
  color: #ffffff;
  border: 2px solid #000;
  text-shadow: none;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 6px;
  height: 35px;
}


.driver-popover.victory-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.victory-theme .driver-popover-close-btn {
  color: #FF691D;
  background-color: transparent;
  top: 4px;
  right: 4px;
  font-size: 26px;
  border: 0;
}


.driver-popover.victory-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #FF691D;
}

.driver-popover.victory-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #FF691D;
}

.driver-popover.victory-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #FF691D;
}

.driver-popover.victory-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #FF691D;
}
//nowrap amount and currency inside of free-spins-award-popup
.amount-nowrap{
  white-space: nowrap;
}