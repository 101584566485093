// This file serves as the base file for variables.
// It should be imported and overridden by brands and must NOT be imported
// in the styles.scss file
//
// It should be imported in other scss files through the following line:
//    @import 'variables';
// This will import the brand overridden values if everything is set up
// correctly inside the angular.json file
$color-brand: #000 !default;
$color-brand-light: #000 !default;
$color-brand-text: #000 !default;
$color-brand-light: #555 !default;
$color-brand-dark: #000 !default;
$color-brand-dark-2: #0e1416;
$color-brand-darker: #000 !default;
$color-brand-accent: #eee !default;
$color-brand-highlight: #f17 !default;
$color-brand-highlight-light: #ff8744 !default;
$color-success: #2da050 !default;
$color-warning: #ffbc16 !default;
$color-danger: #ff423d !default;
$color-site-background: #f4f4f4 !default;
$color-bright: gold;

// Text
$font-size: 10px !default;
$font-family: 'Poppins', 'Arial Narrow', sans-serif !default;
$font-family-secondary: 'Poppins', sans-serif;
$color-text-primary: #000 !default;
$color-text-secondary: #e3e9eb !default;
$color-text-tertiary: #c2c2c2 !default;

// Borders
$color-border: #ededed !default;
$color-border-alt: #d4d4d4 !default;
$color-border-alt-2: white !default;
$color-border-alt-3: #c4c4c4 !default;
$border-radius-size: 0.8rem !default;
$brand-dropdown-border-radius-size: 2px !default;

// Odds
$color-odd: #000 !default;
$color-odd-selected: #eee !default;
$color-odd-selected-text: #000 !default;
$odd-text-size: 13px !default;
$odd-text-weight: normal !default;
$odd-label-text-color: rgba(white, 60%) !default;

// Containers
$color-container-background: #fff !default;
$color-container-header: #000 !default;
$container-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3) !default;
$inner-card-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14) !default;

// Sizes
$mobile-small: 321px !default;
$mobile-medium: 374px !default;
$tablet-size: 768px !default;

// Legend
$legend-in-progress: #ffbd00 !default;
$legend-in-evaluation: #ff9e47 !default;
$legend-won: #2da050 !default;
$legend-partially-won: #50e3c2 !default;
$legend-lost: #ff423d !default;
$legend-void: #6a3b11 !default;
$legend-cashout: #bdb289 !default;
$legend-promotion: #86159d !default;
$legend-cancelled: #c6c83b !default;

// Loading
$loading-background-color: #fff !default;
$loading-bar-color: #000 !default;

// Spinner
$spinner-primary-color: #fff !default;
$spinner-secondary-color: #eee !default;
$spinner-tertiary-color: #ccc !default;
$spinner-background-color: #000 !default;

// Logo
$login-logo-width: 10vh !default;
$login-logo-height: 75% !default;

// Sportsradar widget
$sr-widget-primary: #000 !default;
$sr-widget-home: #000 !default;
$sr-widget-away: #000 !default;
$sr-widget-base: #fff !default;
$sr-widget-text: #000 !default;

// Banner
$banner-button-background: rgba(#000, 0.5) !default;

// Landing Menu
$landing-header-bottom-border-color: #f17 !default;
$landing-header-bg-image: url('/assets/images/backgrounds/header-background-with-gradient.png') !default;
$landing-menu-bg-color-opened: transparent !default;
$landing-menu-bg-color-closed: transparent !default;
$landing-menu-item-color: black !default;
$landing-additional-links-bg-color: transparent !default;
$landing-additional-links-border-color: transparent !default;
$landing-menu-item-shadow: none !default;
$landing-menu-bottom-border-color: #f17 !default;

// Footer
$footer-icons-color: grey !default;

// Badge
$badge-bg-color: #f17 !default;
$badge-text-color: white !default;
$inverse-badge-bg-color: white !default;
$inverse-badge-text-color: #f17 !default;

// Events
$live-badge-bg-color: #f17 !default;
$live-badge-text-color: white !default;
$live-period-color: white !default;
$live-match-time-color: #f17 !default;
$live-match-score-color: #f17 !default;
$match-text-size: 13px !default;
$match-text-weight: normal !default;
$odds-count-text-color: grey !default;

// Sports
$sports-menu-selected-bg-color: grey !default;
$sports-menu-selected-text-color: #f17 !default;
$sports-menu-selected-border-color: grey !default;
$sports-cat-selection-bg-color: black !default;
$sports-cat-selection-text-color: white !default;
$sports-cat-checkbox-separator-width: 0 !default;
$sports-cat-checkbox-separator-color: black !default;
$proceed-bar-bg-color: #f17 !default;
$proceed-bar-text-color: white !default;
$proceed-bar-value-text-color: white !default;
$proceed-bar-border-color: white !default;

// Evaluation
$evaluation-coupon-code-bg-color: #2a2f33;
$evaluation-league-name-bg-color: #353a3e;
