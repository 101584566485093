@import '~src/assets/scss/theme/variables';

// Main
$color-brand: #266569;
$color-brand-light: #277f85;
$color-brand-text: #44aab3;
$color-brand-dark: #262b2f;
$color-brand-dark-2: #252a2d;
$color-brand-darker: #262b2f;
$color-brand-highlight: #ee5c25;
$color-brand-highlight-light: #ff8744;
$color-brand-accent: #9cba7e;
$color-site-background: #1a1c1f;
$color-success: #60d283;
$color-bright: #eeaa25;
$color-error: #ff5955;
// Text
$color-text-primary: #ffffff;
$color-text-secondary: #e3e9eb;
$color-text-tertiary: #767f86;

// Borders
$color-border: #262b2f;
$color-border-alt-2: #252b2f;
$color-border-alt-3: #32383e;

// Odds
$color-odd: transparent;
$color-match-odd-background: #212629;
$color-match-odd-border: #3f4453;
$color-odd-selected: #ee5c25;
$color-odd-selected-text: $color-text-primary;
$odd-text-size: 1.3rem;
$odd-text-weight: normal;
$odd-label-text-color: rgba($color-text-primary, 60%);

// Containers
$color-container-background: #1a1c1f;
$color-container-header: $color-brand-accent;
$container-shadow: none;
$inner-card-shadow: none;

// Loading
$loading-background-color: $color-site-background;
$loading-bar-color: $color-brand;

// Spinner
$spinner-primary-color: $color-brand;
$spinner-secondary-color: $color-brand-highlight;
$spinner-tertiary-color: $color-brand-accent;
$spinner-background-color: $color-site-background;

// Logo
$login-logo-width: 13vh;
$login-logo-height: 100%;

// Sportsradar widget
$sr-widget-primary: $color-brand-highlight;
$sr-widget-home: $color-brand-text;
$sr-widget-away: $color-brand-accent;
$sr-widget-base: $color-site-background;
$sr-widget-text: $color-text-primary;

// Landing Menu
$landing-header-bottom-border-color: $color-brand-highlight;
$landing-header-bg-image: none;
$landing-menu-bg-color-opened: linear-gradient(to bottom, $color-brand-darker, #162e36);
$landing-menu-bg-color-closed: $color-brand-darker;
$landing-menu-item-color: transparent;
$landing-additional-links-bg-color: transparent;
$landing-additional-links-border-color: tranparent;
$landing-menu-bottom-border-color: $color-brand-highlight;
$landing-menu-item-shadow: none;

// Footer
$footer-icons-color: #b5bbc0;

// Badge
$badge-bg-color: $color-brand-highlight;
$badge-text-color: $color-text-primary;
$inverse-badge-bg-color: $color-brand-highlight;
$inverse-badge-text-color: $color-text-primary;

// Events
$live-badge-bg-color: #a30500;
$live-badge-text-color: $color-text-primary;
$live-period-color: $color-text-secondary;
$live-match-time-color: $color-bright;
$live-match-score-color: $color-success;
$match-text-size: 16px;
$match-text-weight: normal;
$odds-count-text-color: $color-text-secondary;

// Sports
$sports-menu-selected-bg-color: #122f31;
$sports-menu-selected-text-color: $color-brand-highlight;
$sports-menu-selected-border-color: #1f5356;
$sports-cat-selection-bg-color: $color-brand-dark;
$sports-cat-selection-text-color: $color-text-primary;
$sports-cat-checkbox-separator-width: 2px;
$sports-cat-checkbox-separator-color: $color-container-background;
$proceed-bar-bg-color: $color-brand-highlight;
$proceed-bar-text-color: $color-text-primary;
$proceed-bar-value-text-color: $proceed-bar-text-color;
$proceed-bar-border-color: lighten($color-brand-highlight, 15%);
$proceed-bar-border-color: lighten($color-brand-highlight, 15%);

// Evaluation
$evaluation-coupon-code-bg-color: #2a2f33;
$evaluation-league-name-bg-color: #353a3e;
