@import '~sanitize.css';
@import '~src/assets/scss/input-overrides';
@import '~src/assets/scss/image-locations';
@import '~src/assets/scss/sweetalert2';
@import '~src/assets/scss/overlay';
@import '~src/assets/scss/globals';
@import '~src/assets/scss/legend-colors';
@import '~src/assets/scss/widget-overrides';
@import '~src/assets/scss/fonts';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~swiper/css/bundle';

html {
  min-width: 300px;
  font-family: $font-family;
  font-size: $font-size;
  color: $color-text-primary;
  scroll-behavior: smooth;
  height: 100%;

  @media screen and (min-width: 1280px) {
    overflow: hidden;
  }
}

body {
  min-width: 300px;
  background-color: $color-site-background;
  height: 100%;
  overflow: auto;

  &.disable-scrolling {
    overflow: hidden;
  }
}

.button {
  cursor: pointer;
}
