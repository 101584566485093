/* tslint:disable */
@import 'variables';
@import '~src/assets/scss/functions';

/*
 * The custom classes prefixed with 'notif-' should be used here where possible so they
 * keep functioning if the Swal team ever decide to change their class names
 */
.notif-wrapper {
  &.swal2-shown {
    animation: fadeIn 0.3s;
    padding: 1.2rem;
  }

  .notif-popup {
    width: 100%;
    background: #2b2d31;
    border: 1px solid #404246;
    box-shadow: 0px 8px 11px 3px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem;

    .notif-title {
      font-size: 20px;
      font-weight: 700;
      padding: 2rem 0;
    }

    .swal2-html-container {
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      padding: 0 0 2rem 0;
    }

    button {
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      border-radius: 8px;
      border: none;
      outline: none;
    }

    .notif-confirm-button {
      background: linear-gradient(270deg, $color-brand-highlight 0%, $color-brand-highlight-light 100%);
      color: #ffffff;
      order: -1;
    }

    .notif-cancel-button {
      background: transparent;
      color: #bfc3c8;
      margin: 1.2rem 0 0 0;
    }

    &.type-success {
      .notif-header .notif-title {
        color: $color-brand-text;
      }
      .swal2-html-container {
        .amount {
          color: $color-brand-text;
          font-weight: 600;
        }
      }
      .timer {
        margin-top: 1.2rem;
        align-self: center;
        position: relative;
        right: -5px;
      }
      .sub-title {
        font-size: 12px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 3rem;
        padding-top: 0;
      }
    }
    &.type-question {
      .swal2-html-container {
        font-size: 20px;
        font-weight: 700;
        padding-top: 1.2rem;
      }
      .notif-confirm-button {
        background: linear-gradient(278.05deg, #266569 0%, #277f85 100%);
      }
    }

    &.type-warning {
      .notif-header .notif-title {
        color: $color-text-primary;
      }
    }

    &.type-error {
      .notif-header .notif-title {
        color: $color-danger;
      }
      .amount {
        color: $color-danger;
        font-weight: 600;
      }
    }

    &.type-info {
      .notif-header .notif-title {
        color: $color-brand-text;
      }
    }
  }
  .notif-popup {
    &.swal2-show {
      animation: zoomIn 0.3s;
    }
  }
}
.notif-container {
  &.swal2-shown {
    animation: fadeIn 0.3s;
  }

  .notif-popup {
    border-radius: 0;
    padding: 1.25rem;

    &.eval {
      background: #161f22;
      padding: 1.25rem 0.25rem;

      .swal2-html-container {
        color: #fff;
      }
    }

    .notif-header .notif-title {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }

    .swal2-html-container {
      font-size: 14px;
      color: $color-text-primary;
      margin: 20px 0 15px;

      &.branded {
        margin: -1.25rem -1.25rem 0;
        padding: 1.25rem;
        color: $color-text-primary;
        background-color: $color-brand;
        font-size: 16px;
      }
    }

    .notif-actions {
      margin: 1em auto 0;
      justify-content: space-between;

      &.eval-buttons {
        justify-content: center;

        .eval-confirm-button {
          margin-left: 15px;
          background: $color-brand-accent;
          color: #161f22;
        }

        .eval-cancel-button {
          margin-right: 15px;
          background: #3e4a4e;
        }
      }

      button {
        min-width: 108px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-text-primary;
        border: none;
        border-radius: 0.8rem;
        outline: none;

        &.notif-cancel-button {
          background: linear-gradient(270deg, $color-brand 0%, $color-brand-light 100%);
        }

        &.notif-confirm-button {
          background: linear-gradient(270deg, $color-brand-highlight 0%, $color-brand-highlight-light 100%);
        }
      }

      &.single-button button {
        width: 100%;
      }
    }

    .notif-footer {
      margin: 1em 0 0;
    }

    &.type-success {
      background-color: $color-brand-dark;

      .notif-header .notif-title {
        color: $color-brand-text;
      }
    }

    &.type-warning {
      background-color: $color-brand-dark;

      .notif-header .notif-title {
        color: $color-text-primary;
      }
    }

    &.type-error {
      background-color: $color-brand-dark;

      .notif-header .notif-title {
        color: $color-danger;
      }
    }
  }

  .notif-toast {
    border-radius: 0;

    .swal2-html-container {
      font-size: 16px;

      .toast-content {
        display: flex;
        align-items: center;

        .toast-icon {
          font-size: 20px;
          margin-right: 10px;
        }

        .toast-text {
          width: 100%;
          text-align: center;
        }
      }
    }

    &.type-success {
      background-color: $color-brand;

      .swal2-html-container {
        color: set-text-color($color-brand);
      }
    }

    &.type-warning {
      background-color: $color-brand-dark;

      .swal2-html-container {
        color: $color-text-secondary;

        .toast-content .toast-icon {
          color: $color-warning;
        }
      }
    }

    &.type-error {
      background-color: $color-brand-dark;

      .swal2-html-container {
        color: $color-danger;
      }
    }
  }

  .notif-popup {
    &.swal2-show {
      animation: zoomIn 0.3s;
    }
  }

  .notif-toast {
    &.swal2-show {
      animation: slideInUp 0.5s;
    }

    &.swal2-hide {
      animation: slideOutUp 0.5s;
    }
  }
}

// Animations copied from the animate.css plugin
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    bottom: -100%;
    visibility: visible;
  }

  to {
    bottom: 0;
  }
}

@keyframes slideOutUp {
  from {
    bottom: 0;
  }

  to {
    visibility: hidden;
    bottom:150%;
  }
}

.swal2-popup {
  background: $color-brand-dark !important;
}

.swal2-container {
  z-index: 10000 !important;
}
